/*
 * Get elem by id, without hash;
 */

(function(factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD
    define(['jquery'], factory);
  } else if (typeof exports === 'object') {
    // CommonJS
    factory(require('jquery'));
  } else {
    // Browser globals
    factory(jQuery);
  }
}(function($) {

  $.create = function(type) {
    return $(document.createElement(type));
  };

  /**
   * Helper function for cross-browser CSS3 support, prepends all possible prefixes to all properties passed in
   * @param {Object} props Ker/value pairs of CSS3 properties
   */
  $.fn.css3 = function(props) {
    var css = {};
    var prefixes = ['webkit', 'moz', 'ms', 'o'];

    for (var prop in props) {
      // Add the vendor specific versions
      for (var i = 0; i < prefixes.length; i++)
        css['-' + prefixes[i] + '-' + prop] = props[prop];

      // Add the actual version
      css[prop] = props[prop];
    }

    this.css(css);
    return this;
  };

  /**
   * Helper function to bind to the correct animation end event
   * @param {function} callback The function to call when the event fires
   */
  $.fn.animationEnd = function(callback) {
    var _this = this;

    var events = ['webkitAnimationEnd', 'animationend', 'oAnimationEnd'];

    var fn = function(event) {
      // Automatically stop listening for the event
      for (var j = 0; j < events.length; j++)
        $(this).off(events[j]);

      // Perform the callback function
      if (callback)
        callback.call(this, event);
    };

    for (var i = 0; i < events.length; i++) {
      this.on(events[i], fn);
    }

    return this;
  };

  /**
   * Helper function to bind to the correct transition end event
   * @param {function} callback The function to call when the event fires
   */
  $.fn.transitionEnd = function(callback) {
    var _this = this;

    var events = ['webkitTransitionEnd', 'transitionend', 'oTransitionEnd'];
    var fn = function(i, event) {
      // Automatically stop listening for the event
      for (var j = 0; j < events.length; j++)
        $(this).off(events[j]);

      // Perform the callback function
      if (callback)
        callback.call(this, event);
    };
    for (var i = 0; i < events.length; i++) {
      this.on(events[i], fn);
    }

    return this;
  };

  $.id = function(id) {
    return $('#' + id);
  };

}));
