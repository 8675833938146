var $ = require('jquery');
var utils = require('./utils');
require('../plugins/jquery.tap');

var DOM_DELEGATES = {
  setShareDelegate: function(delegate, selector) {
    var sel = "a.share-link, a.share_link" || selector;
    $(delegate).on("click", sel, function(e){
      e.preventDefault();
    }).on("tap", sel, shareDelegate);
  },
  setAnchorDelegate: function(delegate, selector) {
    $(delegate).on("tap", ".link-comments" || selector, anchorDelegate);
  }
};

function shareDelegate(e) {
  var SKIP_SCHEMAS = /(whatsapp):\/\//gi,
      SKIP_CSS_CLASS = /link-comments/;
  if (this.href) {
    if (SKIP_SCHEMAS.test(this.href) || SKIP_CSS_CLASS.test(this.className)) {
      e.stopPropagation();
    } 
    else {
      utils.popup(this, e);
    }
  }
}

function anchorDelegate() {
  var $target = $($(this).attr('href'));
  $('html, body').animate({
    scrollTop: $target.offset().top
  }, 500);
  return false;
}

module.exports = DOM_DELEGATES;
