var UIView = require('./UIView');
require('jquery');

/**
 * Create a instance of UIElement
 * @class
 * @extends UIView
 * @param {Object} options
 */
function UIElement(options) {
  UIView.call(this, options);
}
UIElement.prototype = Object.create(UIView.prototype);
UIElement.prototype.defaults = {};
UIElement.prototype.constructor = UIElement;
UIElement.prototype.saveDataset = function(){
  return UIElement.saveDataset(this.$el, this);
};
UIElement.prototype.hasDataset = function(){
  return UIElement.hasDataset(this.$el, this.constructor);
};

UIElement.dataSetName = 'UIElement';

UIElement.hasDataset = function($el, uiElementConstructor){
  return !!$el.data('api-' +  (uiElementConstructor.dataSetName || "UIElement").toLowerCase());
};

UIElement.saveDataset = function($el, uiElementInstance){
  //console.log("save", (uiElementInstance.constructor.dataSetName || "UIElement").toLowerCase());
  $el.data('api-' +  (uiElementInstance.constructor.dataSetName || "UIElement").toLowerCase(), uiElementInstance);
  return uiElementInstance;
};

module.exports = UIElement;
