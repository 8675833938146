var utils  = {};

utils.preventDefault = function(e){
  e.preventDefault();
};

utils.popUpWindow = function(url, title, w, h) {
  var left = (screen.width / 2) - (w / 2);
  var top = (screen.height / 2) - (h / 2);
  return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
};

utils.popup = function(el, event) {
  utils.preventDefault(event);
  utils.popUpWindow(el.getAttribute('href'), '', 600, 400);
};

module.exports = utils;
