var $ = require('jquery');
var UIElement = require('./UIElement');

var dummyStyle = document.createElement('div').style,
  vendor = (function () {
    var vendors = 't,webkitT,MozT,msT,OT'.split(','),
      t,
      i = 0,
      l = vendors.length;

    for (; i < l; i++) {
      t = vendors[i] + 'ransform';
      if (t in dummyStyle) {
        return vendors[i].substr(0, vendors[i].length - 1);
      }
    }
    return false;
  })(),
  TRNEND_EV = (function () {
    if (vendor === false) return false;
    var transitionEnd = {
      '': 'transitionend',
      'webkit': 'webkitTransitionEnd',
      'Moz': 'transitionend',
      'O': 'otransitionend',
      'ms': 'MSTransitionEnd'
    };
    return transitionEnd[vendor];
  })(),
  ANMEND_EV = (function () {
    if (vendor === false) return false;
    var animationEnd = {
      '': 'animationend',
      'webkit': 'webkitAnimationEnd',
      'Moz': 'animationend',
      'O': 'oanimationend',
      'ms': 'MSAnimationEnd'
    };
    return animationEnd[vendor];
  })();

function UIMessageBox(options) {
  UIElement.call(this, options);
  this.timer = 0;
  this.options = $.extend({}, UIMessageBox.options, this.options);
  this.$el.html(this.tpl(this.options.title, this.options.message));
  this.dom = {};
  this.dom.title = this.$el.find('div.ui-messagebox-title');
  this.dom.text = this.$el.find('div.ui-messagebox-text');
  this.dom.buttons = this.$el.find('div.ui-messagebox-buttons');
  this._setup();
};

UIMessageBox.options = {
  close: 'Close',
  title: "",
  message: ""
};

UIMessageBox.prototype = $.extend(Object.create(UIElement.prototype), {

  constructor: UIMessageBox,

  className: 'ui-messagebox',

  _setup: function () {
    this.dom.close = this.$el
      .find('.close')
      .on('click', $.proxy(function (e) {
        e.preventDefault();
        this.close();
      }, this));
    if (this.options.close)
      this.dom.close.show();
  },

  _hide: function (callback) {
    this.$el.slideUp($.proxy(function () {
      callback && callback.call(this);
      if (this.options.onClose)
        this.options.onClose(this);
    }, this));
  },

  _show: function (callback) {
    this.$el.slideDown($.proxy(function () {
      callback && callback.call(this);
      if (this.options.onOpen)
        this.options.onOpen(this);
    }, this));
  },

  _setContent: function (title, text) {
    this.dom.title.html(title || '');
    this.dom.text.html(text || '');
    return this;
  },

  _buildBtns: function (buttons) {
    var that = this,
      listButtons = [];
    $.each(buttons || [], function (label) {
      var button = this;
      listButtons.push($('<button class="ui-messagebox-button" />')
        .text(label)
        .addClass(button.className)
        .on('click', function (e) {
          e.preventDefault();
          button && button.call(that, this, e);
        }).get(0));
    });
    return listButtons;
  },



  /**
   * Makes a question.
   * @param title {String}
   * @param text {String}
   * @param buttons {Object}
   * @exposed
   */
  question: function (options) {
    var that = this;
    buttons = buttons || {};
    that._setContent(options.title, options.text);
    that.dom.buttons.show().empty().append(that._buildBtns(options.buttons));
    return that;
  },


  /**
   * Displays a message.
   * @param title {String}
   * @param text {String}
   * @param delayToClose {Number} seconds for autoclose
   * @exposed
   */
  message: function (options) {
    var that = this;
    var clsName = options.type || 'success';
    that.$el
      .removeClass('ui-messagebox-fail ui-messagebox-success')
      .addClass('ui-messagebox-' + clsName).height();

    that.open().blink(options.blinks || 0);
    that.dom.buttons.empty().hide();
    that._setContent(options.title, options.text);
    if (options.delayToClose)
      this.close(options.delayToClose);
    return that;
  },


  /**
   * Open the box
   * @param callback {function}
   * @exposed
   */
  open: function (callback) {
    this._show(callback);
    return this;
  },


  /**
   * Close the box
   * @param callback {function}
   * @exposed
   */
  close: function (delay, callback) {
    delay ? setTimeout($.proxy(function () {
      this._hide(callback);
    }, this), delay * 1000) : this._hide(callback);
    return this;
  },



  /**
   * Blink the box
   * @param times {Number} number of repeat blink animation.
   * @exposed
   */
  blink: function (times) {
    var that = this;
    if (times) {
      that.$el.one(TRNEND_EV, function (e) {
        that.$el.one(TRNEND_EV, function (e) {
          that.blink(times - 1);
        }).removeClass('ui-messagebox-emphasis');
      }).addClass('ui-messagebox-emphasis');
    }
    return that;
  },


  /**
   * Shake the box
   * @param callback {function}
   * @exposed
   */
  shake: function (callback) {
    var that = this;
    that.$el.one(ANMEND_EV, function (e) {
      if (callback) {
        callback.call(that, this, e);
      }
      that.$el.removeClass('animation-shake')
    }).css('animation-duration', '1s').addClass('animation-shake');
    return this;
  },


  tpl: function (title, text) {
    return '<a class="ui-messagebox-closer close" href="#close" style="display:none" title="' + this.options.close + '"/>' +
      '<div class="ui-messagebox-title">' + title + '</div>' +
      '<div class="ui-messagebox-text">' + text + '</div>' +
      '<div class="ui-messagebox-buttons" style="display:none"></div>';
  }
});

module.exports = UIMessageBox;
