    var $ = require('jquery');

var ERROR_MESSAGE_TLP = '{{MESSAGE}}';
// var $form = null;
/* Cambios realizados en patrones:
      - inclusion de tildes y ñ en alfabeticos
      - inclusion de mayusculas en email
*/
var PATTERNS = {
  alpha: /^[a-zA-ZñÑ\sÃ±Ã‘\']+$/,
  alphanumeric: /^\w+$/,
  extcard: /^[\w-/]+/,
  integer: /^\d+$/,
  decimal: /^[\d|\.,]+$/,
  date: /^[\d|\-\/]+$/,
  email: /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/,
  all: /([^\s])/
};

var opts = {
  fields: []
};

var REG_ATTR_VALIDATE = /^data-validate/;

var VALIDATE_MAP = {
  'email': {
    'fn': 'validateMail',
    'message': 'Formato de correo inválido'
  },
  'require': {
    'fn': 'validateRequire',
    'message': 'Campo obligatorio'
  },
  'alpha' : {
    'fn': 'validateAlpha',
    'message': 'Solo letras'
  },
  'integer': {
    'fn': 'validateInteger',
    'message': 'Solo números'
  },
  'compare': {
    'fn': 'validateCompare',
    'message': 'No coinciden los datos'
  },
  'max-size': {
    'fn': 'validateMaxSize',
    'message': 'Máximo {{size}} caracteres'
  },
  'size': {
    'fn': 'validateSize',
    'message': 'Tiene que ser {{size}} caracteres'
  },
  'min-size': {
    'fn': 'validateMinSize',
    'message': 'Mínimo {{size}} caracteres'
  },
  'alphanumeric' : {
    'fn': 'validateAlphaNumeric',
    'message': 'Solo números y letras'
  },
  'remote' : {
    'fn': 'validateRemote',
    'message': 'Este dato ya existe'
  },
  'extcard': {
    'fn': 'validateExtCard',
    'message': 'Solo números, letras y simbolos - (guión) / (slash)'
  }
};

/* - inclusion del keypress para bloqueo de teclado  */
var EVENT_MAP = {
  'SELECT': ['blur', 'change'],
  'INPUT TEXTAREA': ['blur', 'keyup', 'keypress']
};

var VALIDATE_FUNCS = {
  validateMail: function($el) {
    var val = $.trim(_getValue($el));
    return PATTERNS.email.test(val);
  },
  validateAlphaNumeric: function($el) {
    var val = $.trim(_getValue($el));
    return PATTERNS.alphanumeric.test(val);
  },
  validateExtCard: function($el){
    var val = $.trim(_getValue($el));
    return PATTERNS.extcard.test(val);
  },
  validateRequire: function($el) {
    var val = $.trim(_getValue($el));
    return val !== '' && val !== undefined;
  },
  validateAlpha: function($el){
    var val = $.trim(_getValue($el));
    var charSpecials = ($el.attr('data-validate-aditional')) ? $el.attr('data-validate-aditional').split("") : false;
    return PATTERNS.alpha.test(val.charAt(val.length - 1)) || $.inArray(val.charAt(val.length - 1), charSpecials) != -1;
  },
  validateInteger: function($el){
    var val = $.trim(_getValue($el));
    return PATTERNS.integer.test(val);
  },
  validateCompare: function ($el) {
    var val = $.trim(_getValue($el));
    var parse = _parse($el.data('validate-compare'));
    var $elCompare = $(parse[0]);
    return $elCompare.val() == val;
  },
  /* - asignacion del atributo maxlength para asegurar que solo ingrese el tamaño de caracteres definidos */
  validateMaxSize: function($el){
    var val = $.trim(_getValue($el));
    var _maxSize = $el.attr('data-validate-max-size');
    $el.attr('maxlength', _maxSize);
    return val.length <= +_parse(_maxSize)[0];
  },
  /* - asignacion del atributo maxlength para asegurar que solo ingrese el tamaño de caracteres definidos */
  validateSize: function($el){
    var val = $.trim(_getValue($el));
    var _size = $el.attr('data-validate-size');
    $el.attr("maxlength", _size);
    return val.length == +_parse(_size)[0]
  },
  validateMinSize: function($el){
    var val = $.trim(_getValue($el));
    return val.length >= +_parse($el.data('validate-min-size'))[0];
  },
  validateRemote: function ($el, callback) {
    var fields = opts.fields;
    var id = $el.attr("name") || $el.attr("id");
    if (Object.keys(fields).indexOf(id) != -1) {
      var field = fields[id];
      var data = {};
      var $form = $el.parents('form');

      for (var key in field.data) {
        if ($form.find(field.data[key]).val() != ''){
          data[key] = $form.find(field.data[key]).val();
        }
      }

      if (!$.isEmptyObject(data)){
        $.ajax({
          url: field.url,
          data: data,
          method: field.method,
          dataType: 'json',
          success: function (data) {
            if (field.valueSuccess) {
              field.status = data[field.keySuccess] == field.valueSuccess;
              $el.data('remoteStatus', field.status);
              callback(field.status);
              return;
            }
            field.status = data[field.keySuccess] == true;
            $el.data('remoteStatus', field.status);
            callback(field.status);
          }
        });
      }
    }
  }
};

function _getDataSelectors() {
  return Object.keys(VALIDATE_MAP).map(function(frag) {
    return '[data-validate-' + frag + ']';
  });
}

function _getValue($el) {
  var el = $($el).get(0);
  return 'checkbox,radio'.indexOf(el.type) >= 0 ? (el.checked ? el.value : '') : el.value;
}

function _getRowWrapper($el) {
  var $row = $el.closest('.ui-form-row');
  return $row.length ? $row : $el.parent();
}

function _parse(value) {
  return String(value).split("|");
}

function _buildMessageBlock(message) {
  message = message || 'Este valor es incorrecto';
  return ('<div class="ui-message-error">' + ERROR_MESSAGE_TLP + '</div>').replace('{{MESSAGE}}', message);
}

function _putMessage($row, message) {
  removeMessage($row);
  if (message)
    $row.addClass('ui-error').append(_buildMessageBlock(message));
}

function removeMessage($el) {
  var $row = _getRowWrapper($el);
  $row.removeClass('ui-error').find('.ui-message-error').remove();
}

function displayMessage($el, data) {
  var $row = _getRowWrapper($el);
  switch (data.attr) {
    case 'require':
    case 'email':
    case 'alpha':
    case 'integer':
    case 'alphanumeric':
    case 'extcard':
    case 'remote':
      var msgCharSpecial = ($el.attr('data-validate-aditional') && (data.attr == 'alpha' || data.attr == 'alphanumeric' || data.attr == 'integer')) ? ' y los siguientes caracteres: ' + $el.attr('data-validate-aditional').replace(/äëïöü|ÄËÏÖÜ|áéíóú|ÁÉÍÓÚ|ÂÊÎÔÛ|âêîôû|àèìòù|ÀÈÌÒÙ|ãẽĩõũỹ|ÃẼĨÕŨỸ/g, '').split('').join(' ') : '';
      _putMessage($row, data.value || VALIDATE_MAP[data.attr].message);
      break;
    case 'max-size':
    case 'size':
    case 'min-size':
    case 'compare':
      var tokens = _parse(data.value);
      _putMessage($row, (tokens[1] || VALIDATE_MAP[data.attr].message).replace('{{size}}', tokens[0]));
      break;
    default:
  }
}

function validateControl(el) {

  var $el = $(el);

  if(!$el.is(":visible")) // if input is visible, skip and return true.
    return true;

  var validates = _validates($el),
    isValid = true,
    i,
    keys = Object.keys(validates),
    reqIndex = keys.indexOf('require');

  if ( reqIndex != -1){
    keys.splice(reqIndex, 1);
    keys.splice(0, 0, 'require');
  }

  for (i in keys) {
    if (keys.hasOwnProperty(i)) {
      if (VALIDATE_MAP[keys[i]] && typeof VALIDATE_FUNCS[VALIDATE_MAP[keys[i]].fn] === "function"){
        if (keys[i] == "remote" && typeof $el.data("remoteStatus") == "undefined") {
          continue;
        }else if(keys[i] == "remote" && typeof $el.data("remoteStatus") == "boolean"){
          isValid = $el.data("remoteStatus");
        }else{
          // console.log(keys[i]);
          isValid = VALIDATE_FUNCS[VALIDATE_MAP[keys[i]].fn]($el);
        }
      }


      if (isValid) {
        removeMessage($el);
      } else {
        displayMessage($el, {
          attr: keys[i],
          value: validates[keys[i]]
        });
        break;
      }
    }
  }
  return isValid;
}

function isValid($form) {
  var isValid = true,
    $fields = $($form).find(_getDataSelectors().join(', '));
  $fields.each(function() {
    isValid *= validateControl($(this));
  });

  return isValid;
}

/* fragmento de codigo que incorpora el metodo find en objetos de tipo Array, y pueda ser soportado en IE */
if (!Array.prototype.find) {
  Array.prototype.find = function(predicate) {
    'use strict';
    if (this == null) {
      throw new TypeError('Array.prototype.find called on null or undefined');
    }
    if (typeof predicate !== 'function') {
      throw new TypeError('predicate must be a function');
    }
    var list = Object(this);
    var length = list.length >>> 0;
    var thisArg = arguments[1];
    var value;

    for (var i = 0; i < length; i++) {
      value = list[i];
      if (predicate.call(thisArg, value, i, list)) {
        return value;
      }
    }
    return undefined;
  };
}

/* funcion agregada para el bloqueo del teclado. Verifica el valor de la tecla pulsada y si coincide con los patrones de validacion o es la tecla tab, backspace, tilde..
   la tecla se ejecutara con normalidad y sino se bloquea su uso. */
function _stopKeyPress(e, $el){
  var that = this;
  var code = e.which || e.keyCode || e.charCode;
  var patternType = ['alpha', 'integer', 'alphanumeric', 'extcard'];
  var arrayExceptionsKeys = [8, 9, 37, 39];
  var keyChar = String.fromCharCode(code);
  var charSpecials = ($el.attr('data-validate-aditional')) ? $el.attr('data-validate-aditional').split("") : false;

  function checkPattern(value){
    return Object.keys(_validates($el)).indexOf(value) > -1;
  }

  if (!keyChar.match(PATTERNS[ patternType.find(checkPattern) ]) && $.inArray(code, arrayExceptionsKeys) == -1 && $.inArray(keyChar, charSpecials) == -1){
    e.preventDefault ? e.preventDefault() : (e.returnValue = false);
    return false;
  }
}

/* En este metodo se ha incluido el evento keypress para el bloqueo del teclado */
function _bindEvent($el, type) {
  switch (type) {
    case 'keypress':
          $el.on(type+ ".validate", function(e){
            _stopKeyPress(e, $el);
          });
          break;
    case 'blur':
      $el.on(type+".validate", function(e) {

        if($el.attr("data-validate-remote")){

          VALIDATE_FUNCS[VALIDATE_MAP['remote'].fn]($el, function(b){
            if (b) {
              removeMessage($el);
            } else {
              displayMessage($el, {
                attr: 'remote',
                value: $el.attr("data-validate-remote")
              });
            }
          });
          return;
        }
        validateControl(this)
      });
      break;
    case 'change':
    case 'keyup':
      $el.on(type+".validate", function(e) {
        if ($el.attr("persistent")) {
          if (type == "change") {
            validateControl(this);
          }
          return;
        }
        // var code = e.keyCode;
        // if (!code || code <= 13 || String.fromCharCode(code).match(PATTERNS[$el.attr('type')]))
        //   return;
        // else e.preventDefault();
        validateControl(this);
      });
      break;
    default:
  }
}

function bindEvents($el) {
  for (var i in EVENT_MAP) {
    if (i.indexOf($el.get(0).tagName) != -1) {
      for (var j in EVENT_MAP[i]) {
        _bindEvent($el, EVENT_MAP[i][j]);
      }
    }
  }
}

function _validates($el) {
  if ($el.data('validates'))
    return $el.data('validates');
  var validates = {},
    hasAttr = false;
  [].forEach.call($el.get(0).attributes, function(attr) {
    if (REG_ATTR_VALIDATE.test(attr.name)) {
      hasAttr = true;
      validates[attr.name.substr(14)] = attr.value;
    }
  });
  if (hasAttr)
    $el.data('validates', validates);
  return validates;
}

function form(form, options) {
  var opt = options || {};
  opts = $.extend(opts, opt);
  //var a = [].filter.call(el.attributes, function(at) { return /^data-/.test(at.name); });
  var $form = $(form);
  var $fields = $form.find(_getDataSelectors().join(', '));
  $fields.each(function() {
    var $el = $(this);
    _validates($el);
    bindEvents($el);
  });
  $form.on('submit', function(e) {
    if (!isValid($form)) {
      e.preventDefault();
      $form.removeClass("isValid");
      return false;
    }else{
      $form.addClass("isValid");
    }
  });
}

function refresh($form){
  $form =$($form);
  var $fields = $form.find(_getDataSelectors().join(', '));
  $fields.each(function() {
    var $el = $(this);
    $.removeData($el.get(0), "validates");
  });
  form($form);
}

function setErrorTemplate(tpl) {
    ERROR_MESSAGE_TLP = tpl;
}

module.exports = {
  form: form,
  isValid: isValid,
  setErrorTemplate : setErrorTemplate,
  refresh : refresh
};
