var SitePage = require('../views/masters/SitePage');
var $ = require('jquery');
var UIDropDown = require('../modules/ui/uidropdown');
var Validate = require('../lib/validate');

/**
 * Error404
 * @constructor
 * @description represent Error 404 Page
 * @extends SitePage
 * @param {Object} options - object contain any attributes
 */

function Error404(options){
  SitePage.call(this);
  this.options = options;
}

Error404.prototype = $.extend(Object.create(SitePage.prototype), {

  constructor: Error404,

  /**
   * Executes when document DOM Tree is ready.
   */
  ready: function() {
    console.log("Ready:   ");
    SitePage.prototype.ready.call(this);

    var $formSearch = $('#form-search-error');

    new UIDropDown({
      el: $('#buscador-categorias-error'),
      name: 'tipo'
    }).on('change', function(el) {
      this.$caption.html($(el).find('span').clone());
    });

    $formSearch.on('submit', function(evt) {
      evt.preventDefault();
      var form = this;
      var uncod = encodeURI(form.buscar.value),
          categoria = form.tipo.value;

      if (uncod != "") {
        if (categoria != 'todos') {
          document.location.href = form.getAttribute('action') + 'tipo/' + categoria + "/buscar/" + uncod.replace(/%20/g, "+");
        } else {
          document.location.href = form.getAttribute('action') + "buscar/" + uncod.replace(/%20/g, "+");
        }
        return false;
      } else {
        form.buscar.focus();
        return false;
      }
    });
  },

  /**
   * Executes when the window is fully loaded.
   */
  load: function(){
    console.log("Load: Error404");
  }

});

// Create a instace of this page;
new Error404({ data: {} });
