var View = require('../../views/masters/View'),
  $ = require('jquery'),
  _uniqueuId = -1;

/**
 * Create a instance of UIView (Based in Backbone View)
 * @class
 * @extends View
 * @param {Object} options
 * @param {string} options.tagName [input=div] - The type of dom element
 * @param {string} options.className [input=""] - A css class of element
 * @param {Model|Object} options.model [input=""] - A hash class of element
 */
function UIView(options) {
  View.call(this);
  this.uid = ++_uniqueuId;
  this.dom = {};
  this.options = $.extend({}, this.defaults, options);
  _propertize.call(this, this.options);
  if (this.el instanceof HTMLElement || typeof this.el === 'string')
    this.$el = $(this.el);
  else if (this.el instanceof $)
    this.$el = this.el;
  else
    this.$el = $(document.createElement(this.tagName));
  this.el = this.$el.attr('id', this.$el.attr('id') || this.id).addClass(this.className).get(0);
}

UIView.prototype = $.extend(Object.create(View.prototype), {

  id: "",

  model: {},

  collection: [],

  template: null,

  constructor: UIView,

  tagName: 'div',

  className: '',

  defaults: {},

  _mergeTemplate: function (tpl, data) {
    
    if (typeof tpl === 'function')
      return this.template(data);
    
    if (!(typeof tpl === 'string'))
      return;
    
    for (var i in data) 
      tpl = tpl.replace(new RegExp('{{' + i + '}}', 'g'), data[i]);
    
    return tpl;
  },

  render: function () {
    var str = this._mergeTemplate(this.template, this.model);
    this.$el.html(str);
    return this;
  },

  remove: function () {
    if (this.$el) {
      this.$el.remove();
    } else if (this.el && this.el.parentNode) {
      this.parentNode.removeChild(this.el);
    }
    this.stopListening();
  }
});

/**
 * Extract the props reserverd of UIView
 * @param  {Object} options - The options of view
 * @return {void}
 */
function _propertize(options) {
  var p, i,
    _viewProps = ['tagName', 'className', 'model', 'collection', 'id', 'el', 'template'];
  for (i in _viewProps) {
    p = _viewProps[i];
    this[p] = options[p] || this.constructor.prototype[p] || UIView.prototype[p];
  }
}

module.exports = UIView;
