var UIElement = require('./UIElement');
var $ = require('jquery');

function UIStateIcon(options) {
  UIElement.call(this, options);
  this.$el.css({
    height: this.options.height,
    width: this.options.width
  });
}

UIStateIcon.prototype = $.extend(Object.create(UIElement.prototype), {
  tagName: 'span',
  className: 'ui-stateicon',
  defaults: {
    height: 24,
    width: 24
  },
  constructor: UIStateIcon,
  setType: function (type) {
    this.$el.removeClass('ui-stateicon-success ui-stateicon-fail ui-stateicon-error ui-stateicon-info').addClass('ui-stateicon-' + (type || 'success'));
  },
  show: function(){
    this.$el.css('display', 'inline-block');
  },
  hide: function(){
    this.$el.hide();
  }
});


module.exports = UIStateIcon;
