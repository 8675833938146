var UIElement = require('./UIElement');
var $ = require('jquery');

function UISpinner(options) {
  UIElement.call(this, options);
  this.$el.append($('<span/>').addClass('ui-spinner-indicator')).css({
    height: this.options.height,
    width: this.options.width
  });
  if (this.options.center) {
    this.$el.css({
      position: 'absolute',
      left: '50%',
      top: '50%',
      marginLeft: -((this.options.width / 2)) + 'px',
      marginTop: -((this.options.height / 2)) + 'px',
    })
  }
}

UISpinner.prototype = $.extend(Object.create(UIElement.prototype), {
  tagName: 'span',
  className: 'ui-spinner',
  defaults: {
    height: 50,
    width: 50,
    center: false
  },
  constructor: UISpinner,
  detach: function () {
    return this.$el.detach();
  },
  show: function(){
    this.$el.css('display', 'inline-block');
  },
  hide: function(){
    this.$el.hide();
  }
});


module.exports = UISpinner;
