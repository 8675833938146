/* #### Orbit.js ####
 * Dependencies:
 * - jQuery
 * - UIElement
 */

var $ = require('jquery');
var UIElement = require('./UIElement');

function round(number, decimal) {
  decimal = parseFloat(decimal ? decimal : 2) + 1;
  decimal = parseFloat('10000'.substr(0, decimal));
  return Math.round(number * decimal) / decimal;
}

function UIOrbit(options) {
  UIElement.call(this, options);
  var $element = this.$el;
  if ($element.length < 1 || $element.hasClass('orbit-ed')) return;
  var $arrows = $element.find('.ui-arrows'),
    $pager = $element.find('.ui-pager'),
    $indicator = $element.find('.ui-indicator').hide(),
    $inner = $element.find('.ui-inner'),
    $items = $inner.children('.ui-item'),
    $c_first = ($inner.children('.ui-item-clone-first')) ? $inner.children('.ui-item-clone-first') : "",
    $c_last = ($inner.children('.ui-item-clone-last')) ? $inner.children('.ui-item-clone-last') : "",

    itemsLength = $items.length,
    sizes,
    currentSize,
    page = 0,
    innerWidthIni,
    itemMarginIni,
    itemXPageIni,
    pages,
    interval = ($element.data('interval') || 0) * 1,
    timer,
    sizesAuto = $element.data('size') == 'auto',
    heightAuto = $element.data('height') == 'auto',
    async = $element.data('async') || 0;

  if (heightAuto) $element.on('heightAuto', function() {
    slide();
  });

  var clone_firts, clone_last;
  clone_firts = $items.last().html();
  clone_last = $items.first().html();

  if ($c_first != "" && $c_last != "") {
    $c_first.html(clone_firts);
    $c_last.html(clone_last);
  }

  if (!sizesAuto) {
    sizes = $element.data('size') ? $element.data('size').replace(/:([\w\.]*)/g, ':[$1]').replace(/x/g, ',') : '\"3020\":[1,100,0]';
    sizes = JSON.parse('{' + sizes + '}');
    //console.log(sizes);
  }

  function slide() {
    var move, dif, widthParent, selIni, selFin, selHeight;
    if (sizesAuto) {
      //widthParent = $inner.parent().width();
      widthParent = $items.width();
      //console.log($items.width());
      move = (page * widthParent);
      dif = (move + widthParent - innerWidthIni);
      if (dif > 0) move -= dif;
      $inner.stop().animate({
        'margin-left': (move * -1)
      });
      $items.removeClass('ui-active').eq(page).addClass('ui-active');
    } else {
      if (heightAuto) {
        selIni = sizes[currentSize][0] * page;
        selFin = selIni + sizes[currentSize][0];
        if (itemsLength < selFin) selIni -= selFin - itemsLength;
        $items.slice(selIni, selFin).each(function() {
          selHeight = (selHeight > $(this).outerHeight(true)) ? selHeight : $(this).outerHeight(true);
        });
      } else selHeight = 'auto';
      move = (page * 100);
      dif = (move + 100 - innerWidthIni);
      if (dif > 0) move -= dif + (itemMarginIni / itemXPageIni);
      move += (itemMarginIni * page);
      $inner.stop().animate({
        height: selHeight,
        'margin-left': (move * -1) + '%'
      });
    }
    if ($pager.children().length > 0) $pager.children('li').removeClass('ui-active').eq(page).addClass('ui-active');
  }

  function asyncPage(ix) {
    var $t = $items.eq(ix);

    if (!$t.data("endAsync")) {
      var $im = $t.find("img[data-src]");
      $im.each(function() {
        $(this).attr("src", $(this).data("src")).removeAttr("data-src").addClass("lazy-loaded");
      });
      $t.data("endAsync", true);
    }

  }

  function render() {

    var itemWidthIni, itemMargin, itemWidth, html = '';
    var _pages, dif, size, ml;
    if (sizesAuto) {
      innerWidthIni = 0;
      $items.each(function() {
        innerWidthIni += $(this).width() + 1
      });
      ml = Math.ceil(($element.width() - ($items.width() + 1)) / 2)
      $inner.width(innerWidthIni + ml);
      //_pages = Math.ceil(pages =(innerWidthIni/$inner.parent().width()));
      //console.log($items.size())
      pages = $items.length;
      _pages = $items.length;

      $items.eq(0).css({
        'margin-left': ml
      }).addClass('ui-active');
    } else {

      size = sizes[currentSize];
      itemXPageIni = size[0];
      itemWidthIni = size[1];
      itemMarginIni = size[2];
      innerWidthIni = Math.round((itemWidthIni + itemMarginIni) * itemsLength);
      _pages = Math.ceil(pages = (innerWidthIni / 100));
      $items.removeAttr('style'),
        $inner.removeAttr('style');

      $inner.css('width', (innerWidthIni + (itemMarginIni * pages)) + '%');
      if (heightAuto) {
        setTimeout(function() {
          $inner.css({
            "height": $items.eq(0).outerHeight(true)
          });
        }, 500);
      }

      // itemMargin = lib.utils.round (((itemMarginIni*100)/itemWidthIni) / itemsLength);
      itemMargin = round(((itemMarginIni * 100) / itemWidthIni) / itemsLength);
      itemWidth = (100 / itemsLength) - itemMargin;
      $items.css({
        'width': itemWidth + '%',
        // 'margin-right': (itemMarginIni?lib.utils.round(itemMargin):0)+'%'
        'margin-right': (itemMarginIni ? round(itemMargin) : 0) + '%'
      });
      // dif = 100-((itemMargin+ lib.utils.round(itemWidth))*itemsLength);
      dif = 100 - ((itemMargin + round(itemWidth)) * itemsLength);
      if (dif != 0) {
        //if (itemMargin) $items.filter(':last').css('margin-right', (itemMargin+dif)+'%');
        //else $items.eq(0).css({'width': (itemWidth+( 100-(itemWidth*itemsLength) )) + '%' });
        if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) $items.css({
          'width': itemWidth + 0.0001 + '%'
        });
      }
    }

    if (heightAuto) {

      $(window).on('debouncedresize', function() {
        var h_item = $items.eq(page).height();
        $inner.css("height", h_item + 'px');
      });
    }

    //pager
    $pager.empty();
    page = (page > _pages) ? _pages - 1 : page;
    if (_pages > 1) {
      for (var i = 1; i <= _pages; i++) {
        html += '<li>' + (i) + '</li>'
      };
      $pager.html(html).children('li').on('click', function(e) {
        e.preventDefault();
        var _t = $(this);

        if (_t.hasClass('ui-active')) return;
        page = _t.prevAll().length;

        timer = clearInterval(timer);
        if (typeof timer == "undefined")
          cycle();
        slide();
        //_t.addClass('ui-active').siblings().removeClass('ui-active');
      }).eq(page).trigger('click');
      $pager.show()
    } else {
      $arrows.hide();
      $pager.hide()
    }
  }

  function resize() {

    if (sizesAuto) return render();

    var winWidth = $(window, document).width(),
      widthOld = 0;
    for (var size in sizes) {
      if (winWidth >= widthOld && winWidth < size) {
        if (size != currentSize) {
          currentSize = size;
          render();
        }
        return;
      }
      widthOld = size;
    }
  }

  function cycle() {
    if (interval > 0) {
      timer = setInterval(function() {
        page = page + 1;
        if (page < 0) page = itemsLength - 1;
        if (page >= itemsLength) page = 0;
        slide();
      }, interval)
    }
  }

  function move(dir) {

    var _pages = Math.ceil(pages);
    page += (dir == 'prev' ? (-1) : (1));
    page = (page < 0 ? (_pages - 1) : (page >= _pages ? 0 : page));
    slide();
  }

  $arrows.find('.ui-prev, .ui-next').on('click.libui', function(e) {
    e.preventDefault();
    clearInterval(timer);
    move(($(this).hasClass('ui-prev') ? 'prev' : 'next'));
    cycle();
  });

  if (($element.data('resize') || 'auto') == 'auto') {
    $(window).on('resize.libui orientationchange', resize);
  }

  function touchActions() {
    var mScreen = $element.width(),
      deltaX, perc, time, dif;

    $inner.on('touchstart touchend touchmove', function(e) {
      switch (e.type) {
        case "touchstart":
          clearInterval(timer);
          dif = e.originalEvent.touches[0].pageX;
          $inner.addClass('dragging');
          time = new Date().getTime();
          break;
        case "touchmove":

          deltaX = (e.originalEvent.touches[0].pageX - dif);
          if (deltaX > 10 || deltaX < -10) e.preventDefault();
          perc = deltaX * 100 / mScreen;
          $inner.css("left", ((page * 100 - perc) * -1) + "%");
          break;
        case "touchend":
          $inner.removeClass('dragging');

          if (new Date().getTime() - time < 200) {
            if (perc < -20 && (page + 1) < pages) page += 1;
            else if (perc > 20 && page > 0) page -= 1;
          } else {
            if (perc < -50 && (page + 1) < pages) page += 1;
            else if (perc > 50 && page > 0) page -= 1;
          }
          slide();
          cycle();

          break;
      }
    });
  }

  resize();
  // cycle();
  // touchActions();

  this.resize = resize;
  this.slide = slide;
  this.cycle = cycle;
}

UIOrbit.prototype = $.extend(Object.create(UIElement.prototype), {
  constructor: UIOrbit
});

UIOrbit.dataSetName = 'UIOrbit';

$.fn.UIOrbit = function() {
  return this.each(function() {
    var $el = $(this);
    if (UIElement.hasDataset($el, UIOrbit))
      return;
    new UIOrbit({
      el: $el
    }).saveDataset();
  });
};


module.exports = UIOrbit;
