/* #### Dropdown.js ####
 * Dependencies:
 * - jQuery
 * - UIElement
 */


var UIElement = require('./UIElement');
var $ = require('jquery');
require('../../plugins/jquery.tap');


/**
 * UIDropDown
 * @class
 * @extends UIElement
 */
function UIDropDown(options) {
  UIElement.call(this, options);
  var $el = this.$el;
  this.$body = $("body");
  this.$current = $el.find('li.ui-active');
  this.$caption = $el.children('.ui-caption');
  this.$hidden = $('<input/>').attr({
    type: 'hidden',
    id : this.options.name || 'ui-dropdown',
    name: this.options.name || 'ui-dropdown'
  }).appendTo(this.$el);

  if (this.$current.length) {
    var val = this.$current.attr('data-value');
    if (val !== "" && val !== undefined)
      this.$hidden.val(val);
  }

  this.isOpen = this.options.isOpen === undefined ? $el.hasClass('ui-open') : this.options.isOpen;
  this.bindEvents();
  this._close = function () {
    if (this.isOpen)
      this.close();
  }.bind(this);
}
// UIDropDown.create = function(){
//
//   var html = UIDropDown.makeTemplate({
//     caption: 'Hola',
//     items: [
//       { text: 'hola', disabled: true, value: ''},
//       { text: 'hola', disabled: true, value: ''},
//       { text: 'hola', disabled: true, value: ''}
//     ]
//   });
//
//   return new Dropdown({
//     $el: $(html)
//   });
// };

UIDropDown.prototype = $.extend(Object.create(UIElement.prototype), {

  constructor: UIDropDown,

  defaults: {
    isOpen: false
  },

  bindEvents: function($nameEvent) {
    var that = this;

    this.$caption.on('tap.uidropdown', function(e) {
      if (!that.isOpen)
        e.stopPropagation();
      if (e.originalEvent)
        e.preventDefault();
      e.preventDefault();
      that.toggle();
    });

    this.$el.on('tap.uidropdown', 'li', function(e) {
      e.preventDefault();
      var $listItem = $(this);
      if (that.$current.length && that.$current.get(0) === $listItem.get(0))
        return;
      var newValue = $listItem.attr('data-value');
      that.$hidden.val(newValue);
      that.$current.removeClass('ui-active');
      that.$caption.find("span").text($listItem.addClass('ui-active').text());
      that.$current = $listItem;
      that.trigger('change', this, $listItem.index(), newValue);
    });
  },
  unBindEvents: function() {
    this.$caption.off('tap.uidropdown');
    this.$el.off('tap.uidropdown');
  },
  toggle: function() {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  },
  open: function() {
    var that = this;
    this.isOpen = true;
    this.$el.addClass('ui-open');
    this.trigger('open');
    this.$body.on("tap.uidropdown", this._close)
  },
  close: function() {
    this.isOpen = false;
    this.$el.removeClass('ui-open');
    this.trigger('close');
    this.$body.off('tap.uidropdown', this._close);
  }
});

UIDropDown.dataSetName = 'UIDropDown';

$.fn.UIDropDown = function() {
  return this.each(function(options) {
    var $el = $(this);
    if (UIElement.hasDataset($el, UIDropDown))
      return;
    new UIDropDown($.extend({}, options, {
      el: $el
    })).saveDataset();
  });
};


module.exports = UIDropDown;
