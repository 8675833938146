var $ = require('jquery'),
  UIElement = require('./UIElement');
require('../../plugins/jquery.ends.js');
require('../../plugins/jquery.tap');

function UIModal(options) {
  UIElement.call(this, options);
  var that = this;
  that.$el.html(UIModal.template);
  that.document = $(document);
  that.dom.contentOutside = that.$el.find('.ui-modal-content');
  that.dom.contentDocument = that.$el.find('.ui-modal-document');
  that.dom.contentInside = that.$el.find('.ui-modal-content-inside:first');
  that.dom.overlay = that.$el.find('.ui-modal-overlay');
  that.isOpen = false;
  if (that.options.view)
    that.setView(that.options.view);
  that.onKeyDownProxy = that._onKeyDown.bind(that);
  that.closeHandlerProxy = that._closeHandler.bind(that);
  that._bindEvents();
}

UIModal.template = ['<div class="ui-modal-overlay"></div>',
  '<div class="ui-modal-content">',
  '<div class="ui-modal-content-inside">',
  '<div class="ui-modal-document"></div>',
  '</div>',
  '</div>'
].join('');

UIModal.prototype = $.extend(Object.create(UIElement.prototype), {

  constructor: UIModal,

  className: "ui-modal",

  defaults: {
    overlayCloser: false,
    keyboardClose: false,
    view: null
  },

  _closeHandler: function(e) {
    e.preventDefault();
    this.close();
    this.trigger('close');
  },

  shake: function(){
    var that = this,
        $box = that.dom.contentInside;
    if($.fn.animationEnd){
      $box.animationEnd(function(){
        $box.removeClass('animation-shake');
      }).addClass('animation-shake');
    } else {
      throw "UIModalError: $.fn.animationEnd is required";
    }
  },

  setView: function(view) {
    this.subView = view;
    view.modal = this;
    var that = this,
      fn = function() {
        that.dom.contentDocument.empty();
        that.hasSubView = true;
        that.dom.contentDocument[typeof el === 'object' ? 'append' : 'html'](view.el);
      };
    if (this.isOpen) {
      this._change(function() {
        fn.call(that);
      });
    } else {
      fn.call(this);
    }
    return that;
  },

  open: function() {
    var that = this;

    if (that.isAnimating)
      return;

    if (that.isOpen)
      return;

    that.$el.appendTo('body');
    that._show();
    return that;
  },

  close: function() {
    var that = this;

    if (that.isAnimating)
      return;

    if (!that.isOpen)
      return;

    that._hide();
    return that;
  },

  _onKeyDown: function(e) {
    var that = this;
    if (that.options.keyboardClose && e.keyCode == 27) {
      that.close();
      that.trigger('close');
    }
  },
  _bindEvents: function() {
    var that = this;
    that.dom.contentDocument.on('tap.uimodal', '.close', that.closeHandlerProxy);
    that.document.on('keydown.modal', that.onKeyDownProxy);
    if (that.options.overlayCloser)
      that.dom.overlay.one(Modernizr.touchevents ? 'touchstart.uimodal' : 'click.uimodal', that.closeHandlerProxy);
  },
  _unBindEvents: function() {
    var that = this;
    that.dom.contentDocument.off('tap.uimodal', '.close', that.closeHandlerProxy);
    that.document.off('keydown.modal', that.onKeyDownProxy);
    if (that.options.overlayCloser)
      that.dom.overlay.off('.uimodal');
  },
  _change: function(fn) {
    var that = this,
      end = function() {
        if (typeof fn === 'function')
          fn();
        that.trigger('changeView', that.subView);
      },
      $contentInside = that.dom.contentInside;
    if (Modernizr.csstransitions) {
      $contentInside.transitionEnd(function() {
        $contentInside.transitionEnd(end);
        that.$el.height();
        that.$el.removeClass('ui-modal-change');
      });
      that.$el.height();
      that.$el.addClass('ui-modal-change');
    } else {
      $contentInside.css('display', 'inline-block');
      if (end) end.call(that);
    }
  },
  _show: function() {
    var that = this,
      end = function() {
        that.trigger('openEnd');
        that.isOpen = true;
        that.isAnimating = false;
      },
      $contentInside = that.dom.contentInside;

    that.isAnimating = true;
    if (Modernizr.csstransitions && that.isOpen === false) {
      $contentInside.transitionEnd(end);
      that.$el.height();
      that.$el.addClass('ui-modal-show');
    } else {
      $contentInside.css('display', 'inline-block');
      if (end) end.call(that);
    }
  },
  _hide: function() {
    var that = this,
      end = function() {
        that.trigger('closeEnd');
        that.isOpen = false;
        that.isAnimating = false;
        that.destroy();
      };
    that._unBindEvents();
    that.isAnimating = true;
    if (Modernizr.csstransitions && that.isOpen) {
      that.dom.contentInside.transitionEnd(end);
      that.$el.height();
      that.$el.removeClass('ui-modal-show');
    } else {
      if (end) end.call(that);
    }
  },

  destroy: function() {
    var that = this;
    if (that.destroyed)
      return;
    that.destroyed = true;
    that._unBindEvents();
    that.document = null;
    if (that.subView && that.subView.$el) {
      that.subView.$el.detach();
    }
    that.options.view = null;
    that.remove();
  }
});



  
module.exports = UIModal;
