/* #### lazyload.js ####
 * Dependencies:
 * - jQuery
 * - UIElement
 */
var $ = require('jquery');
var UIElement = require('./UIElement');
var Modernizr = window.Modernizr;

require('../../plugins/jquery.sonar');

function UILazyload(options) {
  UIElement.call(this, options);
  var that = this,
    eventHandler = Modernizr.touchevents ? function() {
      var $el = that._unLazy($(this)).css('display', '').off('scrollin');
      that._setSource($el);
    } : function() {
      var $el = that._unLazy($(this));
      $el.fadeIn('slow', function() {
        $el.css('display', '');
      }).off('scrollin');
      that._setSource($el);
    };
  that.$el.on("scrollin", eventHandler);
}
UILazyload.dataSetName = 'UILazyload';
UILazyload.prototype = $.extend(Object.create(UIElement.prototype), {
  constructor: UILazyload,
  _unLazy: function($el) {
    return $el.hide(0).removeClass('ui-lazy');
  },
  _setSource: function($el) {
    switch ($el.data('type')) {
      case "srcset":
        $el.attr('srcset', $el.data('srcset'));
        break;
      default:
        $el.attr('src', $el.data('src'));
    }
    return $el;
  }
});

$.fn.UILazyload = function() {
  return this.each(function() {
    var $el = $(this);
    if (UIElement.hasDataset($el, UILazyload))
      return;
    new UILazyload({
      el: $el
    }).saveDataset();
  });
};


module.exports = UILazyload;
