var UIElement = require('./UIElement');
var UISpinner = require('./UISpinner');
var UIStateIcon = require('./UIStateIcon');
var $ = require('jquery');

function UIStatusBar(options) {
  UIElement.call(this, options);
  this.uiSpinner = new UISpinner(this.options.spinner);
  this.stateIcon = new UIStateIcon();
  this.dom.text = $('<span/>').addClass('ui-statusbar-text').text(options.text);
  this.dom.img = $('<span/>').addClass('ui-statusbar-img');
  this.$el.append(this.dom.img);
  this.$el.append(this.dom.text);
}

UIStatusBar.prototype = $.extend(Object.create(UIElement.prototype), {
  constructor: UIStatusBar,
  className: 'ui-statusbar',
  defaults: {
    text: "",
    spinner: {
      width: 20,
      height: 20
    }
  },
  setWorkingMessage: function (message, delay) {
    if (typeof message === 'string' || typeof message === 'number') {
      clearTimeout(this.delayTimerId); 
      this.setStatusText(message);
      this.dom.img.html(this.uiSpinner.$el);
      this._showGraph();
      if (!isNaN(delay))
        this.delayTimerId = setTimeout(this.hide.bind(this), delay * 1000);
    } else {
      this.hide();
    }
  },
  setStateMessage: function (message, type, delay) {
    if (typeof message === 'string' || typeof message === 'number') {
      clearTimeout(this.delayTimerId);
      this.setStatusText(message);
      this.dom.img.html(this.stateIcon.$el);
      this.stateIcon.setType(type);
      this._showGraph();
      if (!isNaN(delay))
        this.delayTimerId = setTimeout(this.hide.bind(this), delay * 1000);
    } else {
      this.hide();
    }
  },
  _showGraph: function () {
    this.dom.img.css('display', 'inline-block');
  },
  _hideGraph: function () {
    this.dom.img.hide();
  },
  setStatusText: function (value) {
    if (value)
      this.show();
    this.dom.text.text(value);
  },
  show: function () {
    this.$el.slideDown();
  },
  hide: function () {
    this.$el.slideUp();
    this._hideGraph();
  }
});

UIStatusBar.toTypeName = function (type) {
  return /ok|success|true|1/.test(type) ? 'success' : /fail|error|0|false/.test(type) ? 'error' : /info/.test(type) ? 'info' : undefined;
};


module.exports = UIStatusBar;
