var extend = require('jquery-extend'),
    CustomEvents = require('../../lib/CustomEvents');
/**
 * Create a instance of View
 * @class
 * @param {Object} options - hash that set in this.options
 */
function View(options) {
  this.options = extend({}, this.defaults, options);
}
View.prototype.defaults = {};
extend(View.prototype, CustomEvents);

module.exports = View;
