var Page = require('./page');
var $ = require('jquery');
var UIOrbit = require('../../modules/ui/uiorbit');
var UIDropDown = require('../../modules/ui/uidropdown');
var DOMDelegates = require('../../lib/domdelegates');
var Validate = require('../../lib/validate');
var UIMessageBox = require('../../modules/ui/uimessagebox');
var UIModal = require('../../modules/ui/uimodal');
var UIView = require('../../modules/ui/uiview');
var UIStatusBar = require('../../modules/ui/uistatusbar');
var UILazyload = require('../../modules/ui/uilazyload');
var DOCUMENT_LENGHT = {
  "DNI": 8,
  "CEX": 12,
  "PAS": 12,
  "CDL": 15,
  "CDE": 15,
  "TDI": 15
};
/**
 * SitePage
 * @constructor
 * @extends Page
 * @description used to generic code crosspage
 * @param {Object} options - object contain any attributes
 */
function SitePage(options) {
  Page.call(this);
  this.options = options;
  this.messageBox = new UIMessageBox({
    id: 'header-messagebox'
  });
  this.regStatusBar = new UIStatusBar({
    id: 'minibox-reg-statusbar'
  });
  this.regStatusBar.hide();

  this.loginStatusBar = new UIStatusBar({
    id: 'minibox-login-statusbar'
  });
  this.loginStatusBar.hide();

  this.beforePos = 0;
  this._requesting = {};
  this.visIn = false;
  this.visReg = false;
  Validate.setErrorTemplate('<span class="icon-error"></span><h4 class="error">{{MESSAGE}}</h4>');
  window.visIng = this.visIn;
}

SitePage.prototype = $.extend(Object.create(Page.prototype), {

  constructor: SitePage,

  /**
   * Executes when document DOM Tree is ready.
   */
  ready: function () {
    console.log("Ready: SitePage ok");
    var that = this;

    $('.ui-form-row input, .ui-form-row textarea, .ui-form-row select').on('focus', function () {
      that._showMessageErrorForm($(this).parents('.ui-form-row').find('.error'));
    });

    $('.ui-form-row').on('focus', '.recaptcha_input_area input', function () {
      that._showMessageErrorForm($(this).parents('.ui-form-row').find('.error'));
    });

    $('.ui-form-row').on('click', function (evt) {
      evt.stopPropagation();
    });

    $('.ui-form-row').on('click', 'span.icon-error', function (evt) {
      that._showMessageErrorForm($(this).next('.error'));
    });

    // document.prueba = function (){console.log('entra');}

    // click fuera de caja
    var that = this;

    var $minBoxIngresar = $('.min-box-ingresar'),
      $minBoxRegistrate = $('.min-box-registrate'),
      $form = $('#form-login'),
      $boxRegister = $('#box-registrate'),
      $boxRecuperarPassword = $('#box-recuperar-contrasenia'),
      $boxRecuperarUsuario = $('#box-recuperar-usuario'),
      $btnIngresarHeader = $('.ingresar-button-header'),
      $btnRegistrarseHeader = $('.registrarse-button-header'),
      $olvideContrasena = $('.olvide-contrasena'),
      $volverIngresar = $('.volver-ingresar'),
      $recuperarContrasenaBox = $('.recuperar-contrasenia-box'),
      $ingresaBox = $('.ingresa-box'),
      $recupearUsuario = $('.recuperar-usuario'),
      $volverRecuperarContrasena = $('.volver-recuperar-contrasena'),
      $recuperarUsuarioBox = $('.recuperar-usuario-box'),
      $btnRegistrateLogin = $('#btn-registrate-login'),
      $tipoDocument = $(".tipo_documento"),
      $searchBoxBtn = $('header span i.search'),
      $searhContainer = $('.busqueda-header'),
      $formSearch = $('#form-search'),
      //$flyoutBeneficiario = $('.flyout').not('.flyout-exception'),
      $flyoutBeneficiario = $('#flyout-beneficiarios'),
      $loginRedirect = $form.find("[name='redirect']"),
      $document = $(document),
      $flyoutDocumento = $('#layout-documento'),
      $ListButonShop = $('.button-shop-flyout'),
      $formflyout = $('#form-flyout'),
      $tipoDocumentFlyout = $(".tipo_documento_flyout");


    this.minBoxIngresar = $minBoxIngresar;
    this.minBoxRegistrate = $minBoxRegistrate;
    this.btnRegistrarseHeader = $btnRegistrarseHeader;
    this.btnIngresarHeader = $btnIngresarHeader;

    $('.ui-orbit').UIOrbit();

    var isMobDeviceApple = (/iphone|ipad|iPod/gi).test(navigator.appVersion);
    if(!isMobDeviceApple){
      $('.ui-lazy').UILazyload();
    }else{
      $('.ui-lazy').each(function(){
        $(this).attr('src', $(this).attr('data-src'));
      })
    }

    new UIDropDown({
      el: $('#buscador-categorias'),
      name: 'tipo'
    }).on('change', function (el) {
      this.$caption.html($(el).find('span').clone());
    });

    // Add messagebox to body
    this.messageBox.$el.prependTo(document.body);

    // Add status bar minibox login
    this.loginStatusBar.$el.appendTo($minBoxIngresar);
    //this.loginStatusBar.working('Autenticando..');

    // Add status bar minibox registrar
    this.regStatusBar.$el.appendTo($minBoxRegistrate);
    //this.regStatusBar.working('Procesando información..');

    // Bind delegate to listen press share links
    DOMDelegates.setShareDelegate(document);

    $minBoxIngresar.on('click', function (evt) {
      evt.stopPropagation();
    });

    $minBoxRegistrate.on('click', function (evt) {
      evt.stopPropagation();
    });

    //header ingresar
    $btnIngresarHeader.on('click', function (evt) {
      evt.preventDefault();
      evt.stopPropagation();
      if ($minBoxIngresar.is(":visible")) {
        this._hidePopup($minBoxIngresar, $btnIngresarHeader);
        this.loginStatusBar.hide();
        window.visIng = false;
      } else {
        this._showPopup(this.minBoxIngresar, this.btnIngresarHeader);
        this._hidePopup(this.minBoxRegistrate, this.btnRegistrarseHeader);
        this.regStatusBar.hide();
        window.visIng = true;
      }
    }.bind(this));

    $btnRegistrateLogin.on('click', function () {
      if (this.minBoxRegistrate.is(":visible")) {
        this._hidePopup(this.minBoxRegistrate, this.btnRegistrarseHeader);
        this.regStatusBar.hide();
        window.visIng = false;
      } else {
        this._hidePopup(this.minBoxIngresar, this.btnIngresarHeader);
        this.loginStatusBar.hide();
        this._showPopup(this.minBoxRegistrate, this.btnRegistrarseHeader);
        window.visIng = true;
      }
    }.bind(this));

    var optPopup = window.location.search.replace('?', '');
    if (optPopup) {
      if (optPopup == 'login') {
        $btnIngresarHeader.trigger('click')
        $('#userEmail').focus();
      } else if (optPopup == 'register') {
        $btnRegistrateLogin.trigger('click');
        $('#tipo_documento_suscriptor').focus();
      }
    }

    $(document.body).on('click', function (evt) {
      if (this.visReg) {
        this._hidePopup(this.minBoxRegistrate, this.btnRegistrarseHeader);
        this.regStatusBar.hide();
      }
      if (window.visIng && !$(evt.target).parent('#btn-reg-beneficios').length && $(evt.target)[0].id != 'btn-cupon' && !$(evt.target).parent('#form-activar-invitacion').length && evt.target.id != 'alert') {
        this._hidePopup(this.minBoxIngresar, this.btnIngresarHeader);
        this.loginStatusBar.hide();
      }
      var $msg_error = $('.error');
      if ($msg_error.is(':visible')) $msg_error.hide();
    }.bind(this));

    this.minBoxIngresar.on('click', function (evt) {
      evt.stopPropagation();
    });
    this.minBoxRegistrate.on('click', function (evt) {
      evt.stopPropagation();
    });

    //header registrarse
    $btnRegistrarseHeader.on('click', function (evt) {
      evt.preventDefault();
      evt.stopPropagation();
      if (this.minBoxRegistrate.is(":visible")) {
        this._hidePopup(this.minBoxRegistrate, this.btnRegistrarseHeader);
        this.regStatusBar.hide();
        this.visReg = false;
      } else {
        this._hidePopup(this.minBoxIngresar, this.btnIngresarHeader);
        this.loginStatusBar.hide();
        this._showPopup(this.minBoxRegistrate, this.btnRegistrarseHeader);
        this.visReg = true;
      }
    }.bind(this));

    $document.on('click', '.btn-redirect', function (e) {
      e.preventDefault();
      var href = this.getAttribute("href");
      $loginRedirect.val(href);
      $btnIngresarHeader.click();
    });

    //header recuperar contraseña
    $olvideContrasena.on('click', function () {
      $recuperarContrasenaBox.fadeIn();
      $ingresaBox.hide();
      this.loginStatusBar.hide();
    }.bind(this));

    $volverIngresar.on('click', function () {
      $recuperarContrasenaBox.hide();
      $ingresaBox.fadeIn();
      this.loginStatusBar.hide();
    }.bind(this));

    $recupearUsuario.on('click', function () {
      $recuperarContrasenaBox.hide();
      this.loginStatusBar.hide();
      $recuperarUsuarioBox.fadeIn();
    }.bind(this));

    $volverRecuperarContrasena.on('click', function () {
      $recuperarUsuarioBox.hide();
      this.loginStatusBar.hide();
      $recuperarContrasenaBox.fadeIn();
    }.bind(this));

    //header busqueda
    $searchBoxBtn.on('click', function (evt) {
      evt.preventDefault();
      if ($searhContainer.is(":visible")) {
        //$searchBoxBtn.css('background-position', '0px 0px');
      } else {
        //$searchBoxBtn.css('background-position', '-23px 0px');
      }
      $searhContainer.slideToggle('fast');
    });

    // close busqueda
    $('#close-search').on('click', function (evt) {
      evt.preventDefault();
      $searhContainer.slideToggle('fast');
      $searchBoxBtn.css('background-position', '0px 0px');
    });

    $tipoDocument.find('option').each(function (i, ele) {
      if ($(this).val().split('#')[0] == 'DNI') $(this).attr('data-min-length', 8).attr('data-max-length', 8);
      else $(this).attr('data-min-length', 5).attr('data-max-length', 12);
    });

    $tipoDocument.each(function () {
      var $numDoc = $(this).parents('.ui-form-row').next().find('.numero-documento'),
        validateType = ($(this).val().split('#')[0] == 'DNI') ? 'data-validate-integer' : 'data-validate-extcard',
        lengthType = $(this).find('option[selected="selected"]'),
        minlength = (lengthType.length) ? lengthType.attr('data-min-length') : 8,
        maxlength = (lengthType.length) ? lengthType.attr('data-max-length') : 8;
      $numDoc.removeAttr('data-validate-integer data-validate-extcard data-validate-max-size data-validate-min-size maxlength')
        .attr(validateType, '')
        .attr('data-validate-min-size', minlength)
        .attr('data-validate-max-size', maxlength)
        .attr('maxlength', maxlength);
    });

    // validaciones de formularios

    function onSubmitValidateForm(evt) {
      evt.preventDefault();
      if (Validate.isValid($(this))) {
        console.log('valido');
      } else {
        console.log('no valido');
      }
    }

    function onSubmitValidateFlyout(evt) {
      evt.preventDefault();

      if (Validate.isValid($(this))) {

        $formflyout.find("input[type='submit']")[0].disabled = true;
        $formflyout.find("input[type='submit']")[0].value = "VALIDANDO...";

        var url_action = $formflyout.attr('action'),
            tipo_documento = $formflyout.find("select[name='tipodocumento']")[0].value,
            numero_documento = $formflyout.find("input[name='numdocumento']")[0].value,
            url_submit = $flyoutDocumento.attr('data-link');

        var url = url_action;
        //var url =  "https://dev.2.clubsuscriptores.pe/ws-authenticate"

        $.ajax({
          type: 'POST',
          url: url,
          data: { document_type: tipo_documento, document_number: numero_documento },
          dataType: 'json',
          success: function (data) {
            if(data.status === true){
              $formflyout.find('#div-success').show();
              $formflyout.find('#div-success').css('width', '225px');
              $formflyout.find('#div-success').text('Eres suscriptor, clic en siguiente.');
              $formflyout.find("input[type='submit']")[0].disabled = false;
              $formflyout.find("input[type='submit']")[0].value = "SIGUIENTE";
              $formflyout.find("input[type='submit']")[0].setAttribute("onclick","window.open('" + url_submit + "' ,'_blank')");
            }else{
              $formflyout.find('#div-error').show();
              $formflyout.find('#div-error').css('width', '225px');
              $formflyout.find('#div-error').text('Aún no eres suscriptor.');
              $formflyout.find("input[type='submit']")[0].disabled = false;
              $formflyout.find("input[type='submit']")[0].value = "VALIDAR";
            }
          },
          error: function(data) {
            console.log('Error Servicio ', data);
          }
        });

      }
    }

    // Common Forms

    Validate.form($form);
    Validate.form($formflyout);
    $formflyout.on('submit', onSubmitValidateFlyout);

    $formflyout.keypress(function(evt) {
      if (evt.keyCode == 13 || evt.charCode == 13 || evt.which == 13) $formflyout.trigger('submit', onSubmitValidateFlyout);
    });

    Validate.form($boxRegister);
    $boxRegister.on('submit', onSubmitValidateForm);

    Validate.form($boxRecuperarPassword);
    $boxRecuperarPassword.on('submit', onSubmitValidateForm);

    Validate.form($boxRecuperarUsuario);
    $boxRecuperarUsuario.on('submit', onSubmitValidateForm);

    // Forms ajax
    $form.on('submit', this._login.bind(this));
    $boxRecuperarPassword.on('submit', this._passRecovery.bind(this));
    $boxRecuperarUsuario.on('submit', this._userRecovery.bind(this));
    $boxRegister.on('submit', this._registerUser.bind(this));

    // Update validations rules when change <select> document type

    $tipoDocumentFlyout.on('change', function () {
      var numdoc = $formflyout.find("input[name='numdocumento']")[0];
      switch($(this).val()){
        case "DNI":
          numdoc.value = "";
          numdoc.removeAttribute( 'data-validate-extcard' )
          numdoc.removeAttribute( 'data-validate-min-size' )
          numdoc.removeAttribute( 'data-validate-max-size' )
          numdoc.setAttribute( 'data-validate-integer', '' )
          numdoc.setAttribute( 'data-validate-size', '8' )
          numdoc.setAttribute( 'placeholder', 'Ingresar DNI' );
          numdoc.focus();
          break
        case "CEX":
        case "CDI":
        case "PAS":
          numdoc.value = "";
          numdoc.removeAttribute( 'data-validate-integer' )
          numdoc.removeAttribute( 'data-validate-size' )
          numdoc.setAttribute( 'data-validate-extcard', '' )
          numdoc.setAttribute( 'data-validate-min-size', '5' )
          numdoc.setAttribute( 'data-validate-max-size', '15' )
          numdoc.setAttribute( 'placeholder', 'Ingresar Documento' );
          numdoc.focus();
          break;
      }
      Validate.refresh($formflyout);
    });

    $tipoDocument.on('change', function () {
      var maxlength = 8,
        minlength = 8,
        opSel = $(this).val().split('#')[0];

      $(this).find('option').each(function () {
        if ($(this).val().split('#')[0] == opSel) {
          maxlength = $(this).attr('data-max-length');
          minlength = $(this).attr('data-min-length');
        }
      });

      // var length = DOCUMENT_LENGHT[this.value.split('#')[0]];
      var $form = $(this.form);
      var inputDocument = $form.find(".numero-documento");
      $(inputDocument).attr('data-validate-max-size', maxlength).attr('data-validate-min-size', minlength).val('');
      if (this.value.split('#')[0] == 'DNI') $(inputDocument).attr('data-validate-integer', '').removeAttr('data-validate-extcard');
      else $(inputDocument).attr('data-validate-extcard', '').removeAttr('data-validate-integer');
      Validate.refresh($form);
    });

    // change to active the contact phone icon when the url is /contacto
    var urlCurrent = $(location)[0].pathname,
      cssParams = (urlCurrent.indexOf('/contacto') != -1) ? {
        backgroundPosition: '-23px 0px'
      } : {
        backgroundPosition: '0px 0px'
      };
    $('.call').css(cssParams);

    // dropdown de menu for login ready
    $('.login-ready').on('click', function () {
      $('.min-box-micuenta').slideToggle('fast');
    });

    $(window).on('scroll', this._navBarFixed.bind(this));

    // form de busqueda
    $formSearch.on('submit', function (evt) {
      evt.preventDefault();
      var form = this;

      var uncod = encodeURIComponent(form.buscar.value),
        categoria = form.tipo.value;

      if (uncod != "") {
        if (categoria != 'todos') {
          document.location.href = form.getAttribute('action') + 'tipo/' + categoria + "/buscar/" + uncod.replace(/%20/g, "+").replace(/%2F/g, '');
        } else {
          document.location.href = form.getAttribute('action') + "buscar/" + uncod.replace(/%20/g, "+").replace(/%2F/g, '');
        }
        return false;
      } else {
        form.buscar.focus();
        return false;
      }

    });

    $flyoutBeneficiario.find('.close').on('click', function (evt) {
      evt.preventDefault();
      $flyoutBeneficiario.removeClass('in-view');
    });

    $ListButonShop.each(function () {
      $(this).on('click', function (evt) {
        evt.preventDefault();
        var dataUrl = $(this).attr('data-url');
        $flyoutDocumento.fadeIn('fast');
        $flyoutDocumento.addClass('in-view');
        $flyoutDocumento.attr('data-link', dataUrl);
        $flyoutDocumento.find('.input-documento').val('');
        $flyoutDocumento.find('.tipo_documento_flyout').val('DNI');
        $flyoutDocumento.find('.error').hide();
        $flyoutDocumento.find('.success').hide();
        $flyoutDocumento.find('.button').val('VALIDAR');
        $flyoutDocumento.find('.button').attr('onclick','');
      })
    });

    $flyoutDocumento.find('.input-documento').on('input', function(evt){
      $flyoutDocumento.find('.success').hide();
      $flyoutDocumento.find('.error').hide();
      $flyoutDocumento.find('.button').val('VALIDAR');
      $flyoutDocumento.find('.button').attr('onclick', '');
    });

    $flyoutDocumento.find('.tipo_documento_flyout').on('change', function(evt){
      $flyoutDocumento.find('.success').hide();
      $flyoutDocumento.find('.error').hide();
      $flyoutDocumento.find('.button').val('VALIDAR');
      $flyoutDocumento.find('.button').attr('onclick', '');
    })

    $flyoutDocumento.find('.close').on('click', function (evt) {
      evt.preventDefault();
      $flyoutDocumento.fadeOut('fast');
      $flyoutDocumento.removeClass('in-view');
    });

    var $error_captcha = ($('#challenge-element .errors').length) ? $('#challenge-element .errors').find('li').text() : null;
    if ($error_captcha) {
      var html_error = '<div class="ui-message-error"><span class="icon-error"></span><h4 class="error">' + $error_captcha + '</h4></div>';
      $('#challenge-element').parents('.ui-form-row').addClass('ui-error').append(html_error).find('.errors').remove();
    }

    $('.close-modal').on('click', function (evt) {
      $(this).parents('#modal-agreements').addClass('display_off');
    });

    $('.item_colc .ofert_cs p').wrap("<div class='divp'></div>");

    var $containerText = $('.divp');

    $containerText.each(function () {
      while ($(this).find('p').outerHeight() > $(this).height()) {
        // console.log($(this).find('p').outerHeight(), $(this).height(), $(this).find('p').text());
        $(this).find('p').text(function (i, text) {
          return text.replace(/\W*\s(\S)*$/, '....')
        });
      }
      // console.log('sale', $(this).find('p').outerHeight(), $(this).height(), $(this).find('p').text());
    });

    $('#box-registrate #documento_suscriptor').on('keypress', function (evt) {
      if (evt.keyCode == 13 || evt.charCode == 13 || evt.which == 13) $boxRegister.trigger('submit');
    });

    $('#box-recuperar-usuario #numero_documento').on('keypress', function (evt) {
      if (evt.keyCode == 13 || evt.charCode == 13 || evt.which == 13) $boxRecuperarUsuario.trigger('submit');
    });
  },

  _showMessageErrorForm: function (obj) {
    $('.error').hide();
    obj.show();
  },

  showModalRegister: function () {
    var modalCongrats = new UIModal({
      id: "modal-accomplished",
      overlayCloser: true
    });

    modalCongrats.setView(new UIView({
      el: $('#modal-congrats')
    }));
    modalCongrats.open();
    setTimeout(function () {
      modalCongrats.close();
    }, 3000);
  },

  _login: function (evt) {
    if (!Validate.isValid($(evt.target)))
      evt.preventDefault();
  },

  _registerUser: function (evt) {
    evt.preventDefault();

    if (!Validate.isValid($(evt.target)))
      return;

    if (this._requesting[PATH_REGISTER])
      return;

    var PATH_REGISTER = $('#box-registrate').attr('action');
    var data = {},
      tipo_documento = $('select[name="tipo_documento_suscriptor"]').val(),
      documento = $('input[name="documento_suscriptor"]').val(),
      distrito = 0,
      ciudad = 0,
      that = this,
      delayMsg = 10,
      promise,
      statusbar = that.regStatusBar;

    data.tipo_documento = tipo_documento;
    data.documento = documento;
    data.distrito = distrito;
    statusbar.setWorkingMessage("Buscando..");

    promise = that._sendRequest(PATH_REGISTER, data, 'POST', 'application/x-www-form-urlencoded')
      .done(function (response) {
        var type = UIStatusBar.toTypeName(response.status) || 'info';
        statusbar.setStateMessage(response.message, type, delayMsg);
        if (type === 'success') {
          $(location).attr('href', $('#box-registrate').data('redirect') + '/ciudad_entrega/' + ciudad + '/distrito_entrega/' + distrito + '/id/' + response.suscriptor.id);
        }
      })
      .fail(function (error) {
        statusbar.setStateMessage("Error: " + error.statusText, "error", delayMsg);
      });
  },

  _userRecovery: function (evt) {
    evt.preventDefault();
    if (!Validate.isValid($(evt.target)))
      return;

    if (this._requesting[PATH_USER_RECOVERY])
      return;

    var PATH_USER_RECOVERY = $('#box-recuperar-usuario').attr('action');
    var data = {},
      that = this,
      delayMsg = 2,
      promise, statusbar = that.loginStatusBar;

    $(evt.target).serializeArray().forEach(function (object) {
      data[object.name] = object.value;
    });

    statusbar.setWorkingMessage("Enviando..");

    promise = that._sendRequest(PATH_USER_RECOVERY, data, 'POST', 'application/x-www-form-urlencoded')
      .done(function (response) {
        var type = UIStatusBar.toTypeName(response.status) || 'info';
        statusbar.setStateMessage(response.msg, type, delayMsg);
      })
      .fail(function (error) {
        statusbar.setStateMessage("Error: " + error.statusText, "error", delayMsg);
      });
  },

  _passRecovery: function (evt) {
    evt.preventDefault();

    if (!Validate.isValid($(evt.target)))
      return;

    if (this._requesting[PATH_PASS_RECOVERY])
      return;

    var PATH_PASS_RECOVERY = $('#box-recuperar-contrasenia').attr('action');
    var data = {},
      that = this,
      delayMsg = 2,
      promise, statusbar = that.loginStatusBar;

    data.email = $('input[name="email"]').val();
    data.rol = $('input[name="tipo"]').val();

    statusbar.setWorkingMessage("Enviando..");

    promise = that._sendRequest(PATH_PASS_RECOVERY, data, 'POST', 'application/x-www-form-urlencoded')
      .done(function (response) {
        var type = UIStatusBar.toTypeName(response.status) || 'info';
        statusbar.setStateMessage(response.msg, type, delayMsg);
      })
      .fail(function (error) {
        statusbar.setStateMessage("Error: " + error.statusText, "error", delayMsg);
      });
  },

  _sendRequest: function (url, data, method, contentType) {
    this._requesting[url] = true;
    var xhr = $.ajax({
      url: url,
      type: method,
      data: data,
      contentType: contentType,
      dataType: 'json'
    });
    xhr.always(function () {
      this._requesting[url] = false;
    }.bind(this));
    return xhr;
  },

  _navBarFixed: function (evt) {
    if ($(evt.target).scrollTop() > 0) {
      $('header').addClass('navbar-fixed');
      if ($(evt.target).scrollTop() > this.beforePos) {
        this.beforePos = $(evt.target).scrollTop();
        if ($('header').css('top') == "-110px") {
          $('.navbar-fixed').stop().animate({
            top: '0px'
          }, "slow");
        }
      } else {
        this.beforePos = $(evt.target).scrollTop();
        if ($('header').css('top') == "0px") {
          $('.navbar-fixed').stop().animate({
            top: '-110px'
          }, "slow");
          if (this.minBoxIngresar.is(":visible")) {
            this._hidePopup(this.minBoxIngresar, this.btnIngresarHeader);
            this.loginStatusBar.hide();
          }
          if (this.minBoxRegistrate.is(":visible")) {
            this._hidePopup(this.minBoxRegistrate, this.btnRegistrarseHeader);
            this.regStatusBar.hide();
          }
        }
      }
    } else {
      this.beforePos = 0;
      $('header').removeClass('navbar-fixed');
    }
  },

  _hidePopup: function ($popup, $btn) {
    $popup.slideUp();
    if ($popup[0].className == 'min-box-ingresar') {
      window.visIng = false;
    }
    $btn.css('border', 'none');
  },

  _showPopup: function ($popup, $btn) {
    $popup.slideDown();
    if ($popup[0].className == 'min-box-ingresar') {
      window.visIng = true;
    }
    $btn.css('border', '1px solid #f7c100');
  },

  /**
   * Executes when the window is fully loaded.
   */
  load: function () {
    console.log("Load: SitePage");
  }

});

module.exports = SitePage;
