var View = require('./View');

// Fallback console IE issue
if (!window.console) window.console = {};
if (!window.console.log) window.console.log = function () {};

function Page() {
  View.call(this);
  var doc = document;
  if (doc.attachEvent ? doc.readyState === "complete" : doc.readyState !== "loading")
    setTimeout(this.ready.bind(this), 1);
  else
    doc.addEventListener('DOMContentLoaded', this.ready.bind(this), false);
  window.addEventListener('load', this.load.bind(this));
  window.clubPage = this;
}
Page.prototype = Object.create(View.prototype);
Page.prototype.constructor = Page;
Page.prototype.ready = function () {
  console.log("Ready: Page");
};

Page.prototype.load = function () {
  console.log("Load: Page");
};

module.exports = Page;
